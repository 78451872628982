import useSWR from "swr";
import type { UserRead } from "typescript-axios";
import { UsersApi } from "typescript-axios";
import axios, { getAxiosParams } from "src/lib/axios.config";
import { redirectIfNotAuthorized } from "src/lib/utils";

const USERS_API = new UsersApi(getAxiosParams(), undefined, axios);
export const USE_CURRENT_USER_KEY = '/users/me'

const useCurrentUser = (redirectIfNotLoggedIn: boolean = false) => {
  const fetcher = () =>
    USERS_API.readMeApiV1UsersMeGet()
      .then((res) => {
        return res.data
      })
      .catch((e) => {
        if (redirectIfNotLoggedIn) {
          redirectIfNotAuthorized(e);
        }
        return null;
      });
  return useSWR<UserRead | null>(USE_CURRENT_USER_KEY, fetcher, {
    refreshInterval: 1000 * 60 * 5,
    dedupingInterval: 1000 * 60 * 5,
  });
};

export default useCurrentUser;
